<template>
  <div id="section_qp" class="section_qp">
    <div class="logo"> <a href="/maquinado-industrial"> <img src="../../assets/logonav.png" alt=""></a>
    </div>
    <div class="bloqueqp">
      <h2 class="title_qp">Aviso <span>De Privacidad</span></h2>
      <p>Aviso de privacidad integral
        DavisInd Group, con domicilio en Avenida Tabasco #227, Colonia San Benito, Hermosillo, Sonora, Mex. CP 83190 y
        portal de internet www.davisindgroup.com, es el responsable del uso y protección de sus datos personales, y al
        respecto le informamos lo siguiente: <br><br>
        ¿Para qué fines utilizaremos sus datos personales? <br>
        Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias
        para el servicio que solicita: <br>
        Respuesta a mensajes del formulario de contacto.<br>
        Prestación de cualquier servicio solicitado.<br> <br>
        ¿Qué datos personales utilizaremos para estos fines?<br>
        Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos
        personales:<br>
        Datos de identificación y contacto.<br> <br>
        ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso o ejercer la revocación de
        consentimiento? <br>
        Usted tiene derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del
        uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de
        que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o
        bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como
        oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como
        derechos ARCO.
        Para el ejercicio de cualquiera de los derechos ARCO, debe enviar una petición vía correo electrónico a
        contact@davisindgroup.com y deberá contener: <br><br>

      <ul>
        <li>Nombre completo del titular.
          Domicilio.
          Teléfono.
          Correo electrónico usado en este sitio web.
          Copia de una identificación oficial adjunta.
          Asunto «Derechos ARCO».
          Descripción del objeto del escrito, los cuales pueden ser de manera enunciativa más no limitativa los
          siguientes: Revocación del consentimiento para tratar sus datos personales; y/o Notificación del uso indebido
          del tratamiento de sus datos personales; y/o Ejercitar sus Derechos ARCO, con una descripción clara y precisa de
          los datos a Acceder, Rectificar, Cancelar o bien, Oponerse. En caso de Rectificación de datos personales, deberá
          indicar la modificación exacta y anexar la documentación soporte; es importante en caso de revocación del
          consentimiento, que tenga en cuenta que no en todos los casos podremos atender su solicitud o concluir el uso de
          forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos
          personales. Asimismo, usted deberá considerar que para ciertos fines, la revocación de su consentimiento
          implicará que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con
          nosotros. </li>
      </ul>
      ¿En cuántos días le daremos respuesta a su solicitud? 20 días.<br> <br>
      ¿Por qué medio le comunicaremos la respuesta a su solicitud? contact@davisindgroup.com <br>
      El uso de tecnologías de rastreo en nuestro portal de internet Le informamos que en nuestra página de internet
      utilizamos cookies, web beacons u otras tecnologías, a través de las cuales es posible monitorear su comportamiento
      como usuario de internet, así como brindarle un mejor servicio y experiencia al navegar en nuestra página. Los datos
      personales que obtenemos de estas tecnologías de rastreo son los siguientes: Identificadores, nombre de usuario y
      contraseñas de sesión. Estas cookies, web beacons y otras tecnologías pueden ser deshabilitadas. Para conocer cómo
      hacerlo, consulte el menú de ayuda de su navegador. Tenga en cuenta que, en caso de desactivar las cookies, es
      posible que no pueda acceder a ciertas funciones personalizadas en nuestros sitio web. <br><br>
      ¿Cómo puede conocer los cambios en este aviso de privacidad? <br>
      El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos
      requerimientos legales; de nuestras propias necesidades por los productos o servicios que ofrecemos; de nuestras
      prácticas de privacidad; de cambios en nuestro modelo de negocio, o por otras causas. Nos comprometemos a mantener
      actualizado este aviso de privacidad sobre los cambios que pueda sufrir y siempre podrá consultar las
      actualizaciones que existan en el sitio web www.davisindgroup.com.</p>
    </div>
    <Footer />
  </div>
</template>

<script>
import Navigator from "../../components/Navigator";
import Footer from "../../components/landing/LandingFooter.vue";
export default {
  name: "AvisoDePrivacidad",
  components: {
    Navigator,
    Footer,
  },
};
</script>


<style>
.logodis {
  background-image: url(../../assets/logo-bnr.png) !important;
}

  .bloqueqp {
    padding-top: 1vw;
    padding-bottom: 3vw;
    width: 80%;
    margin: auto;


  }

  .title_qp {
    font-size: 3.6458333333333335vw;
    line-height: 4.635416666666667vw;
    text-align: left;
    margin-bottom: 2.53125vw;
    color: var(--color-2);
  }

  .title_qp span {
    color: var(--color-1);
  }

  .bloqueqp p {
    font-size: large;
    line-height: 150%;
    font-family: var(--MOSB_Thin);

  }
  .bloqueqp p span{
    font-family: var(--MOSB_Regular);
  }

  .bloqueqp ul {
    margin: 1vw 0;
    opacity: .6;
    line-height: 150%;
    padding-left: 0;
    font-style: italic;
  }
  .logo{
    display: flex;
    justify-content: center;
  }

  .logo img {
    padding: 2vw 0;
    filter: brightness(0);
    opacity: 0.4;
  }
</style>